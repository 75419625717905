.spinner {
  animation: spin 500ms linear infinite;
}

.spinner.primary {
  border-top-color: theme("colors.orange.default");
}

.spinner.white {
  border-top-color: theme("colors.white");
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
