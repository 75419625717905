@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "../node_modules/rc-drawer/assets/index.css";

html,
body {
  @apply h-full;
}

#root {
  @apply h-full overflow-hidden overflow-y-auto;
  @apply flex flex-col;
}

.drawer {
  @apply outline-none;
}

.drawer-content-wrapper {
  @apply rounded-md;
}

.drawer-right.drawer-open .drawer-content-wrapper {
  @apply shadow-drawer !important;
}

button:focus {
  outline: none;
}
