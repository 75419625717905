.osip-notif {
  @apply fixed mr-6 z-50;
  width: 24rem;
  max-width: calc(100vw - theme("spacing.8"));
}

.osip-notif-notice {
  @apply bg-white p-2 shadow-raised mb-4;
}

.osip-notif-notice:last-of-type {
  @apply mb-0;
}

.osip-notif-notice-closable {
  @apply flex items-center justify-between;
}

.osip-notif-notice-close {
  @apply cursor-pointer ml-3;
}

.notif-trans-enter {
  opacity: 0;
  animation-duration: 0.45s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.notif-trans-appear {
  opacity: 0;
  animation-duration: 0.45s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.notif-trans-leave {
  animation-duration: 0.45s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.notif-trans-enter.notif-trans-enter-active {
  animation-name: notif-transIn;
  animation-play-state: running;
}

.notif-trans-appear.notif-trans-appear-active {
  animation-name: notif-transIn;
  animation-play-state: running;
}

.notif-trans-leave.notif-trans-leave-active {
  animation-name: notif-transOut;
  animation-play-state: running;
}

@keyframes notif-transIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes notif-transOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
