.input-group .input-group-label {
  @apply text-xs text-lightsubtext mb-3 leading-tight font-body;
}

.input-group-prefixed .input-group-label {
  @apply mb-1 text-size-stepper-step;
}

.checkbox-label {
  @apply text-xs leading-4 text-orange leading-tight font-body font-bold inline-flex items-center;
}

.input-group .input-group-input {
  @apply border border-2 bg-gray-lighter py-2.5 text-gray-input font-body outline-none;
}

.input-group-prefixed .input-group-input {
  @apply border-0 bg-transparent px-0 py-0;
}

.input-group .input-group-input::placeholder {
  @apply text-gray-input text-opacity-75;
}

.input-group-prefixed .input-group-prefix {
  @apply bg-gray-background flex justify-center items-center;
}

.filter-select,
.filter-date {
  @apply w-48;
}

.filter-select .filter-select__control:hover,
.filter-select .filter-select__control--is-focused {
  @apply shadow-none border-gray-border;
}

.filter-select .filter-select__control,
.filter-date-wrapper {
  @apply border-2 border-gray-border bg-gray-filter-bg rounded-none px-2 py-2.5 min-h-0;
}

.filter-select__indicator-separator {
  @apply hidden;
}

.filter-select .filter-select__value-container,
.filter-select .filter-select__indicator {
  @apply p-0;
}

.filter-select .filter-select__indicator svg {
  @apply w-4 h-4 text-gray-icon;
}

.filter-select__value-container {
  @apply h-4;
}

.filter-select .filter-select__placeholder {
  @apply text-xs font-medium text-lightsubtext;
}

.filter-select [class*="-Input"] {
  @apply m-0 p-0 leading-4 overflow-hidden;
}

.filter-select .filter-select__input input {
  @apply w-0 !important;
}

.filter-select .filter-select__option {
  @apply text-subtext text-xs leading-4;
}

.filter-select .filter-select__option:hover,
.filter-select .filter-select__option--is-focused,
.filter-select .filter-select__option--is-selected {
  @apply bg-gray-background !important;
}

.filter-select .filter-select__menu {
  @apply shadow-raised;
}

.filter-select .filter-select__single-value {
  @apply text-xs text-subheading;
}

.filter-date-container input {
  @apply w-full bg-transparent text-xs leading-4 text-lightsubtext outline-none;
}

.filter-date-container input::placeholder {
  @apply opacity-100 text-lightsubtext;
}

.filter-date
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  @apply bg-orange;
}

.filter-date
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-orange-progress;
}

.filter-date .DayPicker-Day--today {
  @apply font-bold;
}

.filter-date .DayPicker-Day {
  @apply p-2 text-subheading text-sm;
}

.filter-date
  .DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply bg-gray-background;
}

.filter-date .DayPicker-Weekday {
  @apply text-sm text-subtext;
}

.filter-date .DayPicker-NavButton {
  @apply text-subtext;
}

.filter-date .DayPicker-Caption > div {
  @apply text-heading text-lg;
}
