.stepper {
  width: 25rem;
}

.stepper-step {
  @apply relative text-disabled flex-1 text-center flex flex-col items-center;
}

.stepper-step-name {
  @apply text-size-stepper-step;
}

.stepper-step::before {
  @apply top-0 w-2 h-2 rounded-full mb-2;
  content: "";
}

.stepper-step-active::before,
.stepper-step-done::before {
  @apply bg-heading;
}

.stepper-step-active,
.stepper-step-done {
  @apply text-heading;
}

.stepper-step::after {
  @apply absolute w-full bg-gray-divider left-0 -z-1;
  top: calc(theme("spacing.1") - (theme("spacing.px") / 2));
  height: calc(theme("spacing.px") * 1);
  left: -50%;
  content: "";
}

.stepper-step-done + .stepper-step::after {
  @apply bg-heading;
}

.stepper-step:first-of-type::after {
  content: none;
}
